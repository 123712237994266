import CrossIcon from '@atlaskit/icon/glyph/cross';
import { token } from '@atlaskit/tokens';
import { css } from '@compiled/react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl-next';

const mainContainerStyle = css({
	opacity: 0,
	boxShadow: '0 4px 6px 3px #42526e29',
	width: '280px',
	backgroundColor: token('elevation.surface', '#ffffff'),
	borderRadius: token('space.050', '4px'),
	transform: 'translateX(-200px)',
	'@media (max-width: 600px)': {
		width: '100%',
		transform: 'translateY(200px)',
	},
});

const closedToastStyle = css({
	opacity: 0,
	transform: 'translateX(-340px)',
	transition: 'opacity .8s ease-in-out, transform .6s ease-in-out',
	'@media (max-width: 600px)': {
		width: '100%',
		transform: 'translateY(300px)',
	},
});

const contentSectionStyle = css({
	borderRadius: token('space.050', '4px'),
	display: 'flex',
	flexDirection: 'column',
	backgroundColor: token('elevation.surface', '#ffffff'),
	textAlign: 'left',
	padding: token('space.300', '24px'),
});

const imageSectionStyle = css({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
});

const showToastAnimationStyle = css({
	opacity: 1,
	transform: 'translateY(0)',
	transition: 'opacity .4s ease-in-out, transform .4s ease-in-out',
	'@media (max-width: 600px)': {
		transform: 'translateY(16px)',
	},
});

const titleStyle = css({
	fontWeight: '400',
	lineHeight: '1.16',
	fontFamily: 'inherit',
	fontSize: '1.5rem',
	marginTop: token('space.200', '16px'),
	marginBottom: token('space.100', '8px'),
});

const descriptionStyle = css({
	margin: `${token('space.100', '8px')} 0`,
});

const closePanelIconContainerStyle = css({
	background: 'transparent',
	border: 0,
	padding: 0,
	cursor: 'pointer',
	display: 'flex',
	alignItems: 'center',
});

type WacToastProps = {
	title: string | JSX.Element;
	description: string | JSX.Element;
	onDismissed?: () => void;
};

/*
 * This component has been migrated from WAC (growth-web-platform) so that it can be rendered
 * instead of ADS's Flag component when this placement is placed on WAC.
 */
export const WacToast = (props: WacToastProps) => {
	const { title, description, onDismissed } = props;

	const [shouldAnimate, setShouldAnimate] = useState(false);
	const [isClosed, setIsClosed] = useState(false);
	const intl = useIntl();

	const dismissToastAriaLabel = intl.formatMessage({
		id: 'growth.app-switcher-discovery-section.wac-toast.close.aria-label',
		defaultMessage: 'Dismiss toast',
		description: 'Used on a X button that dismisses a toast message',
	});

	useEffect(() => {
		setTimeout(() => {
			setShouldAnimate(true);
		}, 100);
	}, []);

	useEffect(() => {
		// auto dismiss
		setTimeout(() => {
			closeToastCard();
		}, 8000);
	}, []);

	const closeToastCard = () => {
		setIsClosed(true);
	};

	const handleOnCloseClick = () => {
		closeToastCard();

		setTimeout(() => {
			onDismissed && onDismissed();
		}, 800);
	};

	return (
		<div
			css={[
				mainContainerStyle,
				shouldAnimate && showToastAnimationStyle,
				isClosed && closedToastStyle,
			]}
		>
			<div css={contentSectionStyle}>
				<div css={imageSectionStyle}>
					<SuccessIcon />
					<button
						id="toast-close-button"
						css={closePanelIconContainerStyle}
						onClick={handleOnCloseClick}
						aria-label={dismissToastAriaLabel}
					>
						<CrossIcon label={dismissToastAriaLabel} size="small" />
					</button>
				</div>
				<h3 css={titleStyle}>{title}</h3>
				<p css={descriptionStyle}>{description}</p>
			</div>
		</div>
	);
};

const SuccessIcon = () => (
	<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM27.8668 16.2282C28.4316 15.6086 28.4144 14.648 27.8154 14.049L27.7617 13.9977C27.142 13.4329 26.1815 13.45 25.5825 14.049L17.2709 22.3606L14.4176 19.5074L14.3639 19.4561C13.7442 18.8913 12.7837 18.9084 12.1847 19.5074C11.568 20.124 11.568 21.1237 12.1847 21.7404L16.1544 25.7101L16.2082 25.7614C16.8278 26.3262 17.7883 26.3091 18.3873 25.7101L27.8154 16.282L27.8668 16.2282Z"
			fill="#82B536"
		/>
	</svg>
);
